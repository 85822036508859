<template>
  <div class="box-div">
    <div>
      <div>所在省份：</div>
      <div>
        <div :class="item.isChoose ? 'active' : ''" @click="choose('areaList', item)"
          v-for="(item, index) in areaList  " :key="index">{{
            item.webName
          }}</div>
      </div>
    </div>
    <div class="line"></div>
    <div>
      <div>学校性质：</div>
      <div>
        <div :class="item.isChoose ? 'active' : ''" @click="choose('characteristic', item)"
          v-for="(item, index) in characteristic " :key="index">
          {{ item.webName
          }}</div>
      </div>
    </div>
    <div class="line"></div>


    <div>
      <div>留学国家：</div>
      <div>
        <div :class="item.isChoose ? 'active' : ''" @click="choose('countryNameList', item)"
          v-for="(item, index) in countryNameList" :key="index">{{
            item.webName
          }}</div>
      </div>
    </div>
    <div class="line"></div>
    <div>
      <div>留学模式：</div>
      <div>
        <div :class="item.isChoose ? 'active' : ''" @click="choose('studyModeNameList', item)"
          v-for="(item, index) in studyModeNameList " :key="index">
          {{ item.webName
          }}</div>
      </div>
    </div>
    <div class="line"></div>
    <div>
      <div>学费范围：</div>
      <div>
        <div :class="item.isChoose ? 'active' : ''" @click="choose('feeNumList', item)"
          v-for="(item, index) in feeNumList" :key="index">
          {{ item.webName }}</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="last_result">
      <div class="result">
        <div style="flex-shrink: 0;">已选结果：</div>
        <div class="isChoose">
          <div @click="delChoose(item)" v-for="(item, index) in chooseList" :key="index"> <img
              src="~@/assets/img/TechnicalCollege/4.png" alt="">
            {{ item.name }}</div>
          <!-- <div style="background: none !important;">筛选出 <span>12</span> 条结果</div> -->
        </div>
      </div>
      <!-- <div style="background: none !important; flex-shrink: 0; ">
        <el-input @change="getLIst()" v-model="majorName"
          style="width: 260px; height: 30px;background: none !important;" placeholder=" ">
          <i slot="append" class="el-input__icon el-icon-search" @click="paperchoose" />
        </el-input>
      </div> -->
    </div>
  </div>
</template>
<script>
import Vue from "vue";

import { Know } from "@/api/know";
let know = new Know();
export default {
  props: {
    testPaperType: {
      type: String,
    },
  },
  data() {
    return {
      search: {
        name: ''
      },
      majorName: '',
      imgList: {},
      countryNameList: [],
      studyModeNameList: [],
      areaList: [],
      schoolTypeList: [],
      chooseList: [],
      characteristic: [],
      feeNumList: [],
      projectType: '',
      projectCategoryId: '',
    };
  },
  async created() {
    this.projectType = localStorage.getItem('projectType');
    // this.projectCategoryId = localStorage.getItem('projectCategoryId');

    this.selectBriefsPcAbroadFiltrate()

  },
  methods: {

    selectBriefsPcAbroadFiltrate() {
      know.getStudyAbroadSchoolFiltrateData({ projectType: this.projectType, projectCategoryId: this.projectCategoryId ? this.projectCategoryId : undefined }).then((res) => {
        this.areaList = res.data.areaList
        this.areaList.unshift({ areaProvinceName: '全部' })
        this.characteristic = res.data.characteristic
        this.characteristic.unshift({ value: '全部' })
        this.countryNameList = res.data.countryNameList
        this.countryNameList.unshift({ countryNameList: '全部' })
        this.studyModeNameList = res.data.studyModeNameList
        this.studyModeNameList.unshift({ studyModeNameList: '全部' })
        this.feeNumList = res.data.feeNumList
        this.feeNumList.unshift({ value: '全部' })
        this.feeNumList.forEach(e => {
          e.webName = e.value ? e.value : `${e.minNumber}--${e.maxNumber}`
          if (e.webName == '全部') {
            e.isChoose = true
          } else {
            e.isChoose = false
            e.webId = e.id
          }
        });
        this.countryNameList.forEach(e => {
          e.webName = e.countryNameList
          if (e.webName == '全部') {
            e.isChoose = true
          } else {
            e.isChoose = false
            e.webId = e.countryId

          }
        });
        this.studyModeNameList.forEach(e => {
          e.webName = e.studyModeNameList

          if (e.webName == '全部') {
            e.isChoose = true
          } else {
            e.isChoose = false
            e.webId = e.studyMode


          }
        });

        this.areaList.forEach(e => {
          e.webName = e.areaProvinceName

          if (e.webName == '全部') {
            e.isChoose = true
          } else {
            e.isChoose = false
            e.webId = e.areaProvince

          }
        });

        this.characteristic.forEach(e => {
          e.webName = e.value
          if (e.webName == '全部') {
            e.isChoose = true
          } else {
            e.isChoose = false
            e.webId = e.value
          }
        });

      });
    },
    choose(name, item) {
      if (name == 'countryNameList') {
        this.replaceItem({ name: item.webName, id: item.webId, sname: name })
      } else if (name == 'studyModeNameList') {
        this.replaceItem({ name: item.webName, id: item.webId, sname: name })
      } else if (name == 'areaList') {
        this.replaceItem({ name: item.webName, id: item.webId, sname: name })
      } else if (name == 'characteristic') {
        this.replaceItem({ name: item.webName, id: item.webId, sname: name })
      } else if (name == 'feeNumList') {
        this.replaceItem({ name: item.webName, id: item.webId, sname: name, minNumber: item.minNumber, maxNumber: item.maxNumber })
      }

      this.replaceItems(name, item)
    },
    replaceItem(newItem) {
      const index = this.chooseList.findIndex(item => item.sname == newItem.sname);
      if (index !== -1) {
        this.$set(this.chooseList, index, newItem);
      }
      else {
        this.chooseList.push(newItem)
      }
      this.chooseList = this.chooseList.filter(item => item.name != '全部');
      this.$emit('chooseList', this.chooseList)
      this.$forceUpdate()
    },
    replaceItems(name, newItem) {
      const index = this.chooseList.findIndex(item => item.sname == name);
      if (index !== -1) {
        if (name == 'countryNameList') {
          const indexs = this.chooseList.findIndex(item => item.id == newItem.webId);
          if (indexs !== -1) {
            this.countryNameList.forEach(e => {
              e.isChoose = false
            });
            newItem.isChoose = true
          } else {
            newItem.isChoose = false
          }
        } else if (name == 'studyModeNameList') {
          const indexs = this.chooseList.findIndex(item => item.id == newItem.webId);
          if (indexs !== -1) {
            this.studyModeNameList.forEach(e => {
              e.isChoose = false
            });
            newItem.isChoose = true
          } else {
            newItem.isChoose = false
          }
        } else if (name == 'areaList') {
          const indexs = this.chooseList.findIndex(item => item.id == newItem.webId);
          if (indexs !== -1) {
            this.areaList.forEach(e => {
              e.isChoose = false
            });
            newItem.isChoose = true
          } else {
            newItem.isChoose = false
          }
        } else if (name == 'characteristic') {
          const indexs = this.chooseList.findIndex(item => item.id == newItem.webId);
          if (indexs !== -1) {
            this.characteristic.forEach(e => {
              e.isChoose = false
            });
            newItem.isChoose = true
          } else {
            newItem.isChoose = false
          }
        } else if (name == 'feeNumList') {
          const indexs = this.chooseList.findIndex(item => item.id == newItem.webId);
          if (indexs !== -1) {
            this.feeNumList.forEach(e => {
              e.isChoose = false
            });
            newItem.isChoose = true
          } else {
            newItem.isChoose = false
          }
        }



      }
      else {
        if (newItem.webName == '全部') {
          if (name == 'countryNameList') {
            this.countryNameList.forEach(e => {
              e.isChoose = false
            });
          } else if (name == 'studyModeNameList ') {
            this.studyModeNameList.forEach(e => {
              e.isChoose = false
            });
          } else if (name == 'areaList') {
            this.areaList.forEach(e => {
              e.isChoose = false
            });
          } else if (name == 'characteristic') {
            this.characteristic.forEach(e => {
              e.isChoose = false
            });
          } else if (name == 'feeNumList') {
            this.feeNumList.forEach(e => {
              e.isChoose = false
            });
          }

          newItem.isChoose = true
        } else {
          newItem.isChoose = false
        }
      }
    },
    paperchoose() {
      this.$emit('majorName', this.majorName)
    },
    delChoose(items) {
      const index = this.chooseList.findIndex(item => item.id == items.id);
      if (index !== -1) {
        this.chooseList.splice(index, 1);
      }
      if (items.sname == 'countryNameList') {
        this.countryNameList.forEach(e => {
          if (e.webName == '全部') {
            e.isChoose = true
          } else {
            e.isChoose = false

          }
        });
      } else if (items.sname == 'studyModeNameList') {
        this.studyModeNameList.forEach(e => {
          if (e.webName == '全部') {
            e.isChoose = true
          } else {
            e.isChoose = false

          }
        });

      } else if (items.sname == 'areaList') {
        this.areaList.forEach(e => {
          if (e.webName == '全部') {
            e.isChoose = true
          } else {
            e.isChoose = false

          }
        });

      } else if (items.sname == 'characteristic') {
        this.characteristic.forEach(e => {
          if (e.webName == '全部') {
            e.isChoose = true
          } else {
            e.isChoose = false
          }
        });
      } else if (items.sname == 'feeNumList') {
        this.feeNumList.forEach(e => {
          if (e.webName == '全部') {
            e.isChoose = true
          } else {
            e.isChoose = false
          }
        });
      }

    }

  },
};
</script>
<style lang="less" scoped>
.box-div {
  width: 1200px;
  // height: 300px;
  background: #FFFFFF;
  border-radius: 3px 3px 3px 3px;
  padding: 20px;

  >div {
    font-family: PingFangSC-Regular, PingFangSC-Regular;
    font-weight: normal;
    font-size: 16px;
    color: #555555;
    display: flex;
    align-items: baseline;

    >div:nth-child(1) {
      flex-shrink: 0;

    }

    >div:nth-child(2) {

      display: flex;
      flex-wrap: wrap;

      >div {
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: normal;
        font-size: 14px;
        color: #333333;
        padding: 4px 15px;
        margin-right: 20px;
        cursor: pointer;
        border-radius: 4px 4px 4px 4px;
      }

      >div:hover {
        background: #F3F7FF;
        color: #3D6CC8;
      }

      .active {
        background: #F3F7FF;
        color: #3D6CC8;
      }
    }
  }
}

.line {
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #EEEEEE;
  margin: 9px 0;
}

.result {
  font-family: PingFangSC-Regular, PingFangSC-Regular;
  font-weight: normal;
  font-size: 16px;
  color: #555555;
  display: flex;
  align-items: baseline;

  >div:nth-child(1) {
    flex-shrink: 0;

  }

  >div:nth-child(2) {

    display: flex;
    flex-wrap: wrap;

    >div {
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: normal;
      font-size: 14px;
      color: #333333;
      padding: 4px 15px;
      margin-right: 20px;
      cursor: pointer;
      border-radius: 4px 4px 4px 4px;
    }

    >div:hover {
      background: #F3F7FF;
      color: #3D6CC8;
    }
  }
}

.isChoose {
  display: flex;
  flex-wrap: wrap;
  width: 700px;

  >div {
    background: #EEEEEE;
    font-family: PingFangSC-Regular, PingFangSC-Regular;
    font-weight: normal;
    font-size: 14px !important;
    color: #555555 !important;
    border-radius: 4px 4px 4px 4px;
    position: relative;
    margin-bottom: 10px;

    img {
      width: 12px;
      height: auto;
      position: absolute;
      right: -3px;
      top: -3px;
    }

  }

  >div:hover {
    background: #EEEEEE !important;
    color: #555555 !important;
  }




}

.last_result {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep .el-input-group__append {
  padding: 0 7px;
  height: 30px;
  vertical-align: baseline;
  background: #3D6CC8;
  color: #fff;
  font-size: 14px;
}

::v-deep .el-input__inner {
  height: 30px;

}

::v-deep .el-input__icon {
  line-height: 30px;

}
</style>
